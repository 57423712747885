import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import ContactForm from "../components/contactform"
import Address from "../components/address"

const StyledContainer = styled.div` 
  width: 90%;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  
`;

export default function ContactPage(props) {
  return (
    <>
    <SEO title="Contact" />
    <StyledContainer>
      <ContactForm props={props} />
      <Address />
    </StyledContainer>
    </>
  )
}